.photo-page {
  margin-top: 3rem;
  width: calc(100% - (400px));
  display: flex;
  flex-direction: column;
  margin-left: 400px;

  .image {
    width: 60%;
    height: 80vh;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-details {
    margin: 2rem auto;
    width: 50%;
    h1 {
      font-size: 5rem;
      color: $white;
    }

    p {
      color: $gray;
      font-size: 1.6rem;
      margin-top: 1rem;
    }

    .categories {
      width: 100%;
      margin-top: 2rem;
      span {
        color: $white;
        padding: 5px 10px;
        margin: 5px;
        border: 1px solid $gray;
        border-radius: 5px;
        user-select: none;
      }
    }
  }

  .photographer {
    @include flex(center, center);
    flex-direction: row;
    width: 50%;
    margin: 2rem auto;
    border: 1px solid $yellow;
    padding: 10px;
    background-color: black;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    &-detail {
      padding: 1rem 3rem;
      color: $white;
      h1 {
        font-size: 2rem;
      }
      p {
        color: $gray;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .photo-page {
    .image {
      width: 80%;
    }
    .image-details {
      width: 80%;
    }
    .photographer {
      width: 80%;
    }
  }
}
@media screen and (max-width: 1000px) {
  .photo-page {
    .image {
      width: 90%;
      height: 70vh;
    }
    .image-details {
      width: 90%;
      h1 {
        font-size: 4rem;
      }
    }
    .photographer {
      width: 90%;
      &-detail {
        padding: 0.2rem 1rem;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .photo-page {
    width: calc(100% - (60px));
    margin-left: 60px;

    .photographer {
      width: 95%;
      &-detail {
        padding: 1rem 1.5rem;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .photo-page {
    .image {
      height: 50vh;
    }

    .photographer {
      width: 95%;
      &-detail {
        padding: 0.5rem 1rem;
      }
    }
  }
}
