* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  border: 0;
  font-size: 100%;
  a {
    text-decoration: none;
  }
}
