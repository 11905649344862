@import '../Styles/components.scss';

.navbar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  height: 100%;
  background-color: rgb(0, 0, 0);
  padding: 5rem 1.5rem;
  border-right: 1px solid $yellow;
  z-index: 1;
  transition: left 1s;

  &::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    bottom: 50%;
    transform: translate(50%, 50%);
    border-radius: 50%;
    border: 2px solid $yellow;
    background-color: black;
  }

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 0%;
    bottom: 50%;
    transform: translate(50%, 50%);
    border-radius: 50%;
    border: 2px solid $yellow;
    z-index: 1;
  }

  .menu-open {
    position: absolute;
    width: 30px;
    height: 20px;
    top: 1rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;

    .menu-line {
      width: 100%;
      height: 2px;
      background-color: $yellow;
      transition: transform 0.5s;
    }
  }

  h1 {
    background-image: url('https://images.pexels.com/photos/2884867/pexels-photo-2884867.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-repeat: repeat;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 4rem;
    font-weight: 900;
    letter-spacing: 2px;
    margin-bottom: 3rem;
    cursor: pointer;
  }

  li {
    margin-bottom: 20px;
    font-size: 1.2rem;
    letter-spacing: 2px;
    i {
      margin-right: 10px;
      text-align: center;
      width: 30px;
    }
  }
  .nav-links {
    color: $white;
    position: relative;
    transition: color 0.3s;
    font-weight: 700;

    &::before {
      @include position(absolute, $bottom: -3px, $left: 50%);
      height: 2px;
      background-color: $yellow;
      width: 0%;
      content: '';
      transition: width 0.2s;
    }
    &::after {
      @include position(absolute, $bottom: -3px, $right: 50%);
      height: 2px;
      background-color: $yellow;
      width: 0%;
      content: '';
      transition: width 0.2s;
    }
    &:hover {
      color: $yellow;

      &::before {
        width: 50%;
      }
      &::after {
        width: 50%;
      }
    }
  }

  .nav-links.active {
    color: $yellow;
    &::before,
    &::after {
      width: 50%;
    }
  }

  .bottom {
    padding-right: 5rem;
    position: absolute;
    color: $gray;
    bottom: 5%;
    user-select: none;

    .socials {
      @include flex('', '', left);
      margin-bottom: 2rem;

      i {
        font-size: 2rem;
        color: $white;
        transition: 0.2s;
        margin-right: 2rem;

        &:hover {
          color: $yellow;
        }
      }
    }

    h2 {
      text-transform: uppercase;
      font-size: 1.2rem;
    }

    input {
      width: 100%;
      height: 40px;
      background-color: transparent;
      outline: 1px solid $gray;
      padding-left: 10px;
      color: $white;
      margin: 10px 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .navbar {
    width: 350px;
  }
}
@media screen and (max-width: 800px) {
  .navbar {
    left: -290px;

    .menu-open {
      display: flex;
    }
    .menu-open.active .menu-line-1 {
      transform: translate(0, 20px);
    }
    .menu-open.active .menu-line-2 {
      transform: rotate(90deg) translate(5px, 0px);
    }
    .menu-open.active .menu-line-3 {
      transform: translate(0, -10px);
    }

    h1 {
      font-size: 3rem;
    }
    .nav-links {
      font-size: 150%;
    }
  }

  .navbar.active {
    left: 0;
  }
}
