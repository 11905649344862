.pricing-page {
  width: calc(100% - (400px));
  margin-left: 400px;
  display: flex;
  overflow: hidden;

  .price {
    position: relative;
    width: calc(100% / 4);
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
      filter: grayscale(40%);
    }
    .price-items {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 4rem 2rem;

      h1 {
        font-size: 3rem;
        color: $white;
      }

      .price-item {
        width: 100%;
        font-size: 1.5rem;
        margin: 2rem 0rem;
        h2 {
          color: $gray;
        }
        h3 {
          color: $white;
        }
      }
      button {
        margin-top: 2rem;
        padding: 5px 10px;
        background-color: transparent;
        color: $yellow;
        border: 1px solid $yellow;
        letter-spacing: 4px;
        font-weight: 800;
        box-shadow: 2px 2px 2px $yellow;
        cursor: pointer;

        &:active {
          box-shadow: none;
          transform: translate(2px, 2px);
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .pricing-page {
    .price {
      .price-items {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .pricing-page {
    flex-wrap: wrap;
    overflow: scroll;
    .price {
      width: calc(100% / 2);
      .price-items {
        padding: 3rem 1rem;
        h1 {
          font-size: 1.6rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .pricing-page {
    width: calc(100% - (350px));
    margin-left: 350px;
    .price {
      width: 100%;
      .price-items {
        padding: 3rem 2rem;
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .pricing-page {
    width: calc(100% - (60px));
    margin-left: 60px;
    .price {
      width: calc(100% / 2);
      .price-items {
        padding: 2rem 2rem;
        h1 {
          font-size: 1.6rem;
        }
        .price-item {
          margin: 1rem 0;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .pricing-page {
    .price {
      width: 100%;
    }
  }
}
